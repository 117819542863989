<template>
    <div class="wrap">
        <!-- <van-nav-bar title="检查预约" left-arrow @click-left="onClickLeft" /> -->
        <div class="info">
            <div class="info_box">
                <div class="box_1">就诊人</div>
                <div class="box_2">{{ info.name }}</div>
            </div>
            <div class="info_box">
                <div class="box_1">就诊卡号</div>
                <div class="box_2">{{cardNo}}</div>
            </div>
            <!-- <div class="info_box">
                <div class="box_1">手机号</div>
                <div class="box_2">{{pPhone}}</div>
            </div>
            <div class="info_box" style="border-bottom: 1px solid #DCDCDC;padding-bottom: 10px;">
                <div class="box_1">身份证号</div>
                <div class="box_2">{{pCardNo}}</div>
            </div>
            <div class="info_box">
                <div class="box_1">就诊医院</div>
                <div class="box_2">{{hospitalName}}</div>
            </div> -->
            <div class="info_box">
                <div class="box_1">检查单号</div>
                <div class="box_2">{{ info.examNo }}</div>
            </div>
            <div class="info_box">
                <div class="box_1">申请科室</div>
                <div class="box_2">{{ info.requestDeptName }}</div>
            </div>
            <div class="info_box">
                <div class="box_1">申请医生</div>
                <div class="box_2">{{ info.requestPhysician }}</div>
            </div>
            <div class="info_box">
                <div class="box_1">检查科室</div>
                <div class="box_2">{{ info.examDeptName }}</div>
            </div>
            <div class="info_box">
                <div class="box_1">报告时间</div>
                <div class="box_2">{{ info.reportDateTime }}</div>
            </div>
            <div class="info_box">
                <div class="box_1">报告人</div>
                <div class="box_2">{{ info.reporter }}</div>
            </div>
            <div class="info_box" style="border-bottom: 1px solid #DCDCDC;padding-bottom: 10px;">
                <div class="box_1">审核人</div>
                <div class="box_2">{{ info.verifiedBy }}</div>
            </div>
            <div>
                <div class="title">
                    检查项目
                    <span class="title_right">{{ info.examItems }}</span>
                </div>
                <div class="info_box2" style="border-bottom: 1px solid #DCDCDC;padding-bottom: 10px;">
                    <div class="box_1">检查所见：</div>
                    <div class="box_2">{{ checkData.description == null ? checkData.description : '暂无' }}</div>
                </div>
                <div class="info_box2" style="border-bottom: 1px solid #DCDCDC;padding-bottom: 10px;">
                    <div class="box_1">诊断意见：</div>
                    <div class="box_2">{{ checkData.impression == null ? checkData.impression : '暂无' }}</div>
                </div>
                <div class="info_box2">
                    <div class="box_1">报告医师：</div>
                    <div class="box_2">{{ info.reporter }}</div>
                </div>
            </div>
        </div>
        <div class="btn dynamic_btn" @click="goDetail">查看影像</div>
        <!-- <div class="btn_1" @click="goDetail">线上预览</div> -->

    </div>
</template>
<script>
import { getList,getCheckDetail } from "@/api/electronicQuery";

export default {
  data() {
    return {
      user: "",
      cardNo: this.$route.query.cardNo,
      pCardNo: "",
      pPhone: "",
      hospitalName: "",
      info: JSON.parse(localStorage.getItem("itemData")),
      infoList:[],
      checkData: [],
    };
  },
  created() {
    this.getdata();
   window.scrollTo(0, 0)
    this.checkDetail()
  },
  methods: {
    getdata() {
      getList({
        patientId: this.$route.query.cardNo,
      }).then((res) => {
        if (res.data.code == "200") {
          this.dataList = res.data.data;
        }
      });
    },
    // 检查详情
    checkDetail(){
        getCheckDetail({ examNo: this.info.examNo }).then((res) => {
            if (res.data.code == "200" && res.data.data.examNo!=null)  {
                this.checkData = res.data.data
                // console.log(this.checkData,this.checkData)
            }
        });
    },
    onClickLeft() {
      this.$router.push({ path: "/electronicQuery" });
    },
    goDetail(item) {
      this.$router.push({
         path: "/electronicView" ,
         query: {
          cardNo:this.$route.query.cardNo
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
    min-height: 100vh;
    background: #F9FDFE;
    padding-bottom: 16px;

    .info {
        margin: 16px;
        background: #FFFFFF;
        box-shadow: 0px 5px 5px 1px rgba(239, 247, 246, 1);
        border-radius: 10px 10px 10px 10px;
        padding: 10px;

        .info_box {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            margin-bottom: 10px;

            .box_1 {

                font-size: 15px;
                color: #333333;
            }

            .box_2 {


                color: #666666;
                font-size: 14px;
            }
        }

        .title {
            font-weight: bold;
            color: #1AB29B;
            font-size: 16px;

            .title_right {
                float: right;
                font-weight: bold;
                color: #333333;
                font-size: 14px;
            }
        }

        .content {
            margin-top: 12px;
            color: #666666;
            font-size: 14px;
        }

        .info_box2 {
            // display: flex;
            margin-top: 12px;

            .box_1 {
                font-size: 15px;
                color: #333333;
                line-height: 30px;
            }

            .box_2 {
                color: #666666;
                font-size: 14px;
            }
        }
    }


    .btn {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        background: #1AB29B;
        border-radius: 23px 23px 23px 23px;
        margin: 16px 26px;
    }

    .btn_1 {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #1AB29B;
        border-radius: 23px 23px 23px 23px;
        margin: 0 26px;
        border: 1px solid #1AB29B;
    }
}

/deep/.van-hairline--top-bottom:after,
.van-hairline-unset--top-bottom:after {
    border-width: 0px 0;
}

/deep/.van-stepper {
    float: right;
}

/deep/.van-nav-bar__content {
    background-color: #1AB29B;
    color: #fff;
}

/deep/.van-nav-bar__title {
    color: #fff;
    font-size: 18px;
    max-width: 100%;
}

/deep/.van-icon-arrow-left {
    color: #fff;
}
</style>
